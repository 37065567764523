<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
    data() {
        return {
            title: "Penandatangan",
            dataget: this.$route.query,
            penandatangan: [],
            configuration: [],
            data_penyusunan: null,
            ketersediaan: null,
            catatan_ketersediaan: null,
            modalPassword: false,
            password: null,
        };
    },
    mounted() {
        // console.log(this.dataget);
        this.getKonfig();
        this.getDetailPenyusunan();
    },
    methods: {
        getKonfig() {
            let self = this;
            var config_login = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/konfig-login",
                headers: {
                    Accept: "application/json",
                },
            };
            axios(config_login)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.configuration = response_data_fix.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getDetailPenyusunan() {
            let self = this;
            // get data detail penyusunan berdasarkan id
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "public/penyusunan-muk-detail",
                params: {
                    id: self.dataget.surat_id_penyusunan,
                },
                headers: {
                    Accept: "application/json",
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.status == true) {
                        self.data_penyusunan = response_data_fix;
                        self.jadwal_asesor = response_data_fix.penyusunan_muk_asesor;
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        konfimasiKetersediaan() {
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            let self = this;
            self.modalPassword = false;
            axios
                .put(
                    process.env.VUE_APP_BACKEND_URL_VERSION + "penyusunan-muk/ubah-status-kehadiran-asesor",
                    {
                        keterangan_kehadiran: self.ketersediaan,
                        notes: self.catatan_ketersediaan,
                        penyusunan_muk_id: self.dataget.surat_id_penyusunan,
                        asesor_id: self.dataget.uid,
                        password: self.password,
                    },
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + localStorage.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var res_fix = response.data.data;
                    console.log(res_fix);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Ketersediaan anda berhasil dikirim",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            self.$router.push({ name: "dashboard" });
                            // window.close();
                        }
                    });
                })
                .catch((e) => {
                    // this.axiosCatchError = e.response;
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: e.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    console.log(e.response);
                    // Swal.close();
                });
        },
    },
};
</script>
<template>
    <div class="account-pages my-5 pt-5">
        <div
            class="bg-overlay"
            v-bind:style="{
                background: 'url(' + configuration.LOGIN_BACKGROUND + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no repeat',
                backgroundPosition: 'center',
            }"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <b-tabs content-class="p-3 text-muted">
                                <b-tab active class="border-0">
                                    <template v-slot:title>
                                        <span class="d-inline-block d-sm-none">
                                            <i class="fas fa-home"></i>
                                        </span>
                                        <span class="d-none d-sm-inline-block">Detail Penyusunan</span>
                                    </template>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <table class="table table-bordered table-striped">
                                                        <tbody>
                                                            <tr>
                                                                <td style="width: 350px"><strong>Nomor Surat Tugas Penyusunan</strong></td>
                                                                <td>: {{ data_penyusunan?.surat_penyusunan_nomor }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Tanggal Surat Tugas Penyusunan</strong></td>
                                                                <td>: {{ data_penyusunan?.surat_penyusunan_tanggal }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Perihal</strong></td>
                                                                <td>: {{ data_penyusunan?.perihal }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Skema Sertifikasi</strong></td>
                                                                <td>: {{ data_penyusunan?.skema_code }} - {{ data_penyusunan?.skema_name }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Metode Sertifikasi</strong></td>
                                                                <td>: {{ data_penyusunan?.metode_sertifikasi_nama }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Ketersediaan Anda</b> <span class="text-danger">*</span></td>
                                                                <td>
                                                                    <div class="form-group">
                                                                        <v-select :options="['Bersedia', 'Tidak Bersedia']" label="nama_metode_sertifikasi" v-model="ketersediaan" placeholder="Pilih Ketersediaan Anda"></v-select>
                                                                    </div>
                                                                    <div class="form-group" v-if="ketersediaan == 'Tidak Bersedia'">
                                                                        <label for="">Alasan Tidak Bersedia <span class="text-danger">*</span></label>
                                                                        <textarea v-model="catatan_ketersediaan" id="" cols="30" rows="5" class="form-control"></textarea>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div class="text-end">
                                                        <div class="btn btn-primary" v-on:click="modalPassword = true"><i class="fas fa-paper-plane"></i> Kirim Ketersediaan</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal v-model="modalPassword" title="Konfirmasi Password" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <form @submit.prevent="konfimasiKetersediaan">
        <div class="form-group">
            <!-- <label for="">Alasan Penolakan</label> -->
            <input type="password" required class="form-control" v-model="password" placeholder="Password Anda" />
        </div>
        <div class="form-group text-end">
            <button class="btn btn-primary mt-3" type="submit"><i class="fas fa-save"></i> Konfirmasi</button>
        </div>
            </form>
        </div>
    </b-modal>
</template>
